/* Подключение локального шрифта Inter */
@font-face {
    font-family: 'Inter';
    src: url('../public/fonts/Inter-Regular.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: url('../public/fonts/Inter-SemiBold.woff2') format('woff2');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

/* Определение цветовых переменных */
:root {
    --background-color: #ffffff;
    --text-color: #1F2937;
    --font-size-base: 16px;
    --button-bg: #03A9F4;
    --button-hover-bg: #0288D1;
    --button-text: #ffffff;
    --highlight-hover-bg: #81D4FA; /* Более светлый синий для выделения */
    --highlight-bg: #B3E5FC; /* Ещё светлее при наведении */
    --highlight-text: #01579B; /* Тёмный текст для читаемости */
}

/* Общие стили и сброс */
* {
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    box-sizing: border-box;
/*    margin: 0; */
    padding: 0;
    font-size: var(--font-size-base);
    line-height: 1.5; /* Улучшенная читаемость */
}

html,
body,
#root,
.dh-root,
div.dh-root > div.dh-sl-vbox {
    height: 100%;
    overflow: hidden;
}

body {
    background-color: var(--background-color);
    color: var(--text-color);
}

/* Flex-контейнеры */
.dh-vbox {
    display: flex;
    flex-direction: column;
}

.dh-hbox {
    display: flex;
    flex-direction: row;
}

.dh-body {
    padding: 2px 20px;
}

.dh-scroll {
    overflow: auto;
    min-height: 0px;
}

.dh-page {
    height: 100%;
    position: relative;
}

.dh-loading {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    background-color: rgba(255, 255, 255, 0.75);
    width: 100%;
    height: 100%;
    top: 0px;
    transition: opacity 0.5s ease-in;
}

/* Кнопки */
.dh-buttons {
    padding: 6px;
    background-color: #F5F5F5;
    border-radius: 6px;
    display: flex;
    align-items: center;
    gap: 6px;
}

/* Кнопки */
.dh-buttons button {
    background-color: var(--button-bg);
    color: var(--button-text);
    border: none;
/*    padding: 10px 16px; /* Увеличены отступы */
    border-radius: 6px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
    transition: background-color 0.2s, transform 0.1s;
}

.dh-buttons button:hover {
    background-color: var(--button-hover-bg);
    transform: translateY(-2px); /* Более выраженный эффект */
}

.dh-buttons button.bp5-button.bp5-minimal {
    background-color: transparent;
    color: #3B82F6;
    box-shadow: none;
}

.dh-buttons button.bp5-button.bp5-minimal:hover {
    background-color: #E5E7EB;
    color: #2563EB;
}

.dh-dialog-buttons {
    display: flex;
    justify-content: flex-end;
    gap: 8px;
}

.dh-dialog-buttons button {
/*     background-color: #3B82F6; */
    color: #FFFFFF;
    border: none;
    padding: 6px 12px;
    border-radius: 4px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    transition: background-color 0.2s;
}

.dh-dialog-buttons button:hover {
    background-color: #2563EB;
}

/* Навигация */
.bp5-navbar,
.bp5-navbar-group {
    height: 48px;
    background-color: #1F2937;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.bp5-navbar {
    display: flex;
    justify-content: space-between;
}

.bp5-navbar-group button.bp5-button.bp5-minimal {
    color: #FFFFFF !important;
    text-decoration: none;
    outline: none !important;
    box-shadow: none !important;
    padding: 8px 12px;
}

.bp5-navbar-group button.bp5-button.bp5-minimal:focus,
.bp5-navbar-group button.bp5-button.bp5-minimal:active {
    outline: none !important;
    box-shadow: none !important;
}

.bp5-navbar-group button.bp5-button.bp5-minimal:hover {
    background-color: rgba(255, 255, 255, 0.2);
    color: #FFFFFF !important;
    border-radius: 5px;
}

.bp5-navbar-group button.bp5-button.bp5-minimal.bp5-active {
/*    background-color: #3B82F6; */
    color: #FFFFFF !important;
    border-radius: 5px;
}

.bp5-navbar-heading {
    white-space: nowrap;
    color: #FFFFFF;
    font-size: 18px;
    font-weight: 600;
}

.dh-menu-item {
    min-width: 200px;
}

/* Формы */
.dh-form-row {
    width: 100%;
    display: flex;
    flex-direction: row;
}

.dh-form-col {
    flex: 1 1 100px;
    margin-right: 20px;
}

.dh-form-col:last-child {
    margin-right: 0;
}

.dh-form-item {
    display: flex;
    flex-direction: row;
}

.dh-form-item > label {
    flex: 10 1 100px;
}

.dh-form-item > div.bp5-form-content {
    flex: 30 1 100px;
    overflow-wrap: break-word; /* Перенос текста */
    max-width: 100%; /* Ограничение ширины */
    overflow: hidden; /* Убираем прокрутку */
}

.dh-form-item.dh-wide > label {
    flex: 10 1 100px;
}

.dh-form-item.dh-wide > div.bp5-form-content {
    flex: 70 1 330px;
    overflow-wrap: break-word;
    max-width: 100%;
    overflow: hidden;
}

.bp5-form-group {
    margin-bottom: 5px;
}

.bp5-label {
    line-height: initial !important;
}

button.dh-combo-button {
    width: 100%;
}

.bp5-button-text {
    flex: 1 1 auto;
}

.dh-monospace {
    font-family: "Liberation Mono", monospace;
}

.dh-multiline {
    white-space: pre-wrap;
    height: auto !important;
    line-height: normal !important;
    padding-top: 4px;
    padding-bottom: 4px;
}

.dh-textview {
    overflow: auto;
    height: auto;
    min-height: 28px;
/*    background-color: #F5F5F5; */
    line-height: 28px;
    border-radius: 4px;
    padding: 0px 8px;
}

.dh-multiline.dh-all-text {
    max-height: unset;
    line-height: unset;
}

.dh-break-any {
    line-break: anywhere;
}

.bp5-control-group .bp5-popover-target,
.bp5-control-group .bp5-popover-target div,
.bp5-control-group .bp5-popover-target button {
    height: 100%;
}

/* Таблица */
.dh-table {
    border-collapse: separate;
    border: 1px solid #E5E7EB;
    border-spacing: 0;
    border-radius: 6px;
    overflow: hidden;
    table-layout: fixed;
    position: relative;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    background-color: #FFFFFF;
}

.dh-table th {
    border: 1px solid #E5E7EB;
    box-sizing: border-box;
    text-overflow: ellipsis;
    overflow: hidden;
    min-width: 0;
    padding: 10px 6px;
    background-color: #F5F5F5;
    color: #1F2937;
    font-weight: 600;
}

.dh-table td {
    border: 1px solid #E5E7EB;
    box-sizing: border-box;
    text-overflow: ellipsis;
    overflow: hidden;
    min-width: 0;
    padding: 6px;
    color: #1F2937;
}

.dh-table > tbody > tr:nth-child(even) {
    background-color: #FAFAFA;
}

.dh-table > tbody > tr.dh-row:hover {
    background-color: #E1F5FE !important;
    color: #0277BD !important;
}

/* Строка выделения в гриде */
.dh-table > tbody > tr.dh-selected-row {
    background-color: var(--highlight-bg) !important;
    color: var(--highlight-text) !important;
    box-shadow: inset 0 0 0 2px var(--highlight-hover-bg);
}

.dh-table > tbody > tr.dh-selected-row:hover {
    background-color: var(--highlight-hover-bg) !important;
}

.dh-header-resizer {
    background-color: transparent;
    cursor: ew-resize;
    width: 5px;
    height: 100%;
    display: block;
    position: absolute !important;
    top: 0;
    margin: 0;
    padding: 0;
    z-index: 10;
    transform: translateX(-8px);
}

.dh-visible-resizer {
    display: none;
    height: 100%;
    width: 2px;
    border: 1px dotted #10B981;
    position: absolute;
    top: 0;
    margin: 0;
    padding: 0;
    z-index: 11;
}

/* Диалог */
.bp5-dialog {
    position: absolute;
    padding-bottom: 20px;
    border-radius: 6px;
    background: #E1F5FE;
/*    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);*/
}

.dh-dialog-body {
    background-color: #FFFFFF;
    flex: 1 1 200px;
    overflow: hidden;
}

.dh-dialog-resizer {
    position: absolute;
    bottom: 0;
    right: 0;
    cursor: nwse-resize;
}

.bp5-dialog-header {
    background: #E1F5FE;
    color: #0277BD;
    border-bottom: 1px solid #81D4FA;
}

/* Layout */
.dh-root,
.dh-sl-vbox,
.dl-sl-pages-block {
    display: flex;
    flex-direction: column;
}

.dh-sl-vbox {
    height: 100%;
    position: relative;
}

.dh-sl-hbox {
    display: flex;
    flex-direction: row;
    position: relative;
    height: 100%;
}

div.dh-root > div.dh-sl-vbox {
    flex: 1 1 0px;
}

.dh-sl-boxsizer-v {
    flex: 0 0 4px;
    height: 4px;
    width: 100%;
    background: #E5E7EB;
    cursor: ns-resize;
}

.dh-sl-boxsizer-v:hover,
.dh-sl-boxsizer-h:hover {
    background: #D1D5DB;
}

.dh-sl-boxsizer-h {
    flex: 0 0 4px;
    height: 100%;
    width: 4px;
    background: #E5E7EB;
    cursor: ew-resize;
}

.dh-sl-boxblock,
.dh-sl-hbox,
.dh-sl-vbox {
    overflow: hidden;
}

.dh-sl-boxblock-v {
    width: 100%;
}

.dh-sl-boxblock-h {
    height: 100%;
}

.dh-sl-boxptr-v {
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: #10B981;
}

.dh-sl-boxptr-h {
    position: absolute;
    width: 2px;
    height: 100%;
    background-color: #10B981;
}

.dh-sl-pages-headers {
    flex: 0 0 24px;
    background: #F5F5F5;
    color: #1F2937;
    max-height: 24px;
    overflow-x: hidden;
    display: flex;
    position: relative;
}

.dh-menubutton {
    position: absolute;
    right: 0px;
    background: #E1F5FE;
}

.dh-page-block-invisible {
    display: none;
}

.dh-page-block {
    flex: 1 1 0px;
    overflow: hidden;
}

.dh-sl-tab {
    border: 1px solid #E5E7EB;
    border-bottom: none;
    border-radius: 6px 6px 0 0;
    background: #E5E7EB;
    height: 100%;
    padding: 0 6px;
    cursor: pointer;
    flex: 0 5 auto;
    min-width: 30px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #6B7280;
    font-size: 16px;
}

.dh-sl-tab:hover {
    background: #D1D5DB;
    color: #0277BD;
}

.dh-sl-tab-selected {
    border-bottom: none;
    background: var(--highlight-bg); /* Используем переменную для консистентности */
    color: var(--highlight-text);
    flex: 0 1 auto;
    font-size: 16px;
}

.dh-sl-tab-selected:hover {
    background: var(--highlight-hover-bg);
    color: var(--highlight-text);
}

.dh-root {
    display: flex;
    flex-direction: column;
}

.dh-root > div.dh-sl-hbox,
.dh-root > div.dh-sl-vbox,
.dh-root > div.dh-sl-pages-block {
    flex: 1 1 0px;
}

.dh-sl-pages-block {
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.dh-sl-floatbox {
    box-shadow: inset 0 0 30px rgba(0, 0, 0, 0.4);
    outline: 1px dashed #9e9e9e;
    margin: 1px;
    transition: all 200ms ease;
}

.dh-mini-button {
    margin: 0;
    padding: 0;
    color: inherit;
    background: none;
    border: none;
    border-radius: 3px;
    height: 24px;
    width: 24px;
    pointer-events: auto;
}

.dh-mini-button:hover {
    color: #6B7280;
    background-color: rgba(255, 255, 255, 0.6);
}

.dh-sl-pages-headers span.bp5-popover-wrapper {
    display: inline-block;
}

.dh-collapse-body {
    padding: 10px 20px;
    border: 1px solid #E5E7EB;
    background-color: #F5F5F5;
}

.filesDropArea {
    border: 2px dotted #3B82F6;
    background-color: #FFFFFF;
}

.filesDropArea.dropover {
    background-color: #E1F5FE;
    border: 2px dotted #10B981;
}

.dh-files-page {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.dh-files-body {
    flex: 1 1 100px;
    padding: 10px;
    display: flex;
    flex-direction: column;
}

.dh-iconlist {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: start;
    align-items: flex-start;
}

.dh-iconlist-block {
    display: flex;
    flex-direction: column;
    width: 150px;
    height: 150px;
    align-items: center;
    cursor: pointer;
    padding-top: 8px;
}

.dh-iconlist-block:hover {
    color: #3B82F6;
}

.dh-iconlist-block:hover > .dh-iconlist-image {
    background-color: #E1F5FE;
}

.dh-iconlist-image {
    width: 96px;
    height: 96px;
    background-color: #E1F5FE;
    border-radius: 8px;
    position: relative;
}

.dh-iconlist-image > img {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
}

.dh-iconlist-label {
    text-align: center;
    color: #6B7280;
}

.groupbox {
    border: 2px solid #81D4FA;
    padding: 9px;
    border-radius: 8px;
    margin-bottom: 8px;
    margin-top: 8px;
}

.groupbox-title {
    position: relative;
    top: -20px;
    background: #FFFFFF;
    padding: 2px 4px;
    font-weight: 600;
    color: #1F2937;
}

.dh-hline {
    border-top: 2px solid #E5E7EB;
    height: 1px;
    margin-bottom: 8px;
    margin-top: 8px;
    flex: 0 0 2px;
}

.dh-vline {
    border-left: 2px solid #E5E7EB;
    width: 1px;
    margin-left: 8px;
    margin-right: 8px;
    flex: 0 0 2px;
}

/*.dh-negative {
    color: #EF4444;
}
*/

.dh-filter-wrap .bp5-control-group {
    flex-wrap: wrap;
}

.dh-filter-wrap .dh-filter-btn {
    flex: 0 0 auto !important;
}

.filter .dh-buttons {
    background-color: white;
}

/* Blueprint Fixes */
.bp5-select-popover .bp5-menu {
    max-width: 500px;
    max-height: 600px;
    overflow: auto;
    padding: 0;
}

.bp5-button {
    white-space: nowrap;
    min-height: 28px; 
    max-height: 28px;
    font-size: 16px;
    border-radius: 4px;    
}

.bp5-datepicker {
    display: flex;
}

.bp5-timepicker {
    width: 240px;
    margin-bottom: 8px;
}

.bp5-timepicker-arrow-row {
    display: none;
}

.bp5-timepicker-input {
    width: 50px;
}

.bp5-input {
    height: 28px;
    line-height: 28px;
    border-radius: 4px;    
}

.bp5-switch {
    margin-top: 0px;
    margin-bottom: 0px;
}

.dh-header-text {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.header-label {
    flex-grow: 1;
    text-align: center;
}

.sort-icon {
    flex-shrink: 0;
    margin-left: 8px;
}

/* Добавленный стиль для bp5-control-group */
.bp5-control-group:not(.bp5-vertical) > :not(:last-child) {
    margin-right: 4px;
}